async function injectElements() {
  if (
    !document.dispatchEvent(
      new CustomEvent("beforeCSRFInjectAll", {
        cancelable: true,
      })
    )
  ) {
    return;
  }

  const elements = document.querySelectorAll(".csrf-inject:not(.csrf-inject--injected)");
  const injectElements = {};
  const promises = [];

  elements.forEach((element) => {
    const injectElement = {
      element: element,
      id: element.getAttribute("data-csrf-id"),
      uri: element.getAttribute("data-csrf-uri"),
      selectors: element.getAttribute("data-csrf-params") ?? 'meta[name="csrf-token"]:content',
    };

    if (
      document.dispatchEvent(
        new CustomEvent("beforeCSRFInject", {
          cancelable: true,
          detail: injectElement,
        })
      )
    ) {
      const url = injectElement.uri;
      injectElements[url] = injectElements[url] ?? [];
      injectElements[url].push(injectElement);
    }
  });

  for (const url in injectElements) {
    promises.push(replaceUrls(url, injectElements[url]));
  }

  await Promise.all(promises);

  document.dispatchEvent(new CustomEvent("afterCSRFInjectAll"));
}

async function replaceUrls(url, injectElements) {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  if (response.status >= 300) {
    return null;
  }

  const responseText = await response.text();
  const responseJson = JSON.parse(responseText);

  injectElements.forEach((injectElement) => {
    if (injectElement.selectors) {
      const selectors = injectElement.selectors.split(",");
      for (const selector of selectors) {
        const [selectorForQuery, targetAttribute] = selector.split(":");
        const elements = document.querySelectorAll(selectorForQuery);

        elements.forEach((element) => {
          element.setAttribute(targetAttribute, responseJson.csrf_token);
          element.classList.add("csrf-inject--injected");

          document.dispatchEvent(
            new CustomEvent("afterCSRFInject", {
              detail: element,
            })
          );
        });
      }

      injectElement.element.classList.add("csrf-inject--injected");
    }
  });
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", injectElements);
} else {
  injectElements();
}
